#Register {
  .register--container {
    box-shadow: 5px 5px 5px #ddddddd1;

    a {
      color: #fff;
    }

    .register--selectedImage {
      width: 70px;
      height: 70px;
    }
  }
}
html[dir="ltr"] {
  .register--container {
    input {
      border: 1px solid #e4e4eb;
      border-radius: 8px;
    }
    .register--showConfirmPassword {
      left: unset !important;
      right: 10px;
      top: 0px;
    }
  }
}
