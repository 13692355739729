html[dir="rtl"] {
    thead th:first-child {
      border-radius: 0px 12px 0 0px;
    }
    thead th:last-child {
      border-radius: 12px 0px 0px 0;
    }
  }
  html[dir="ltr"] {
    thead th:first-child {
      border-radius: 12px 0px 0px 0;
    }
    thead th:last-child {
      border-radius: 0px 12px 0 0px;
    }
  }