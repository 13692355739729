 
#userBranch,
#contact-us-form-page {
  .userBranch--container-padd {
  }
  .userBranch--title {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .userBranch--label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: start;
    justify-content: start;
    align-items: start;
    color: #555770;
  }
  .userBranch--input {
    background: #ffffff;
    border: 1px solid #e4e4eb;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;

    &::placeholder {
      font-size: 14px;
      text-align: start;
      color: #999999;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
  .userBranch--btn {
    width: 195px;
    height: 45px;
    background: #0063f7;
    border-radius: 8px;
    box-shadow: none;
    text-align: center;

    &:hover {
      background: $nav--links-color-hover;
    }
  }
  .userBranch--showPassword {
    position: relative;
    width: 100%;
  }
  .userBranch--showConfirmPassword {
    position: absolute;
    vertical-align: middle;
    left: 10px;
    top: 0px;

    img {
      cursor: pointer;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .userBranch--title {
      font-size: 18px;
    }
    .userBranch--label {
      font-size: 14px;
      color: #555770;
    }
    .userBranch--input {
      &::placeholder {
        font-size: 12px;
      }
    }
    .userBranch--btn {
      width: 120px;
      height: 35px;
    }
  }
  @media (max-width: 767px) {
    .userBranch--mobile {
      width: 100%;
    }
    .userBranch--title {
      font-size: 18px;
      justify-content: center;
    }
    .userBranch--label {
      font-size: 14px;
    }
    .userBranch--input {
      align-items: start;
      text-align: start;
      justify-content: start;

      &::placeholder {
        font-size: 12px;
      }
    }
    .userBranch--btn {
      width: 40%;
      height: 35px;
    }
  }
  .form-select {
    font-size: 14px;
    border: 1px solid #e4e4eb;
    box-sizing: border-box;
    height: 38px;
  }
}

html[dir="ltr"] .userBranch--showConfirmPassword {
  left: unset !important;
  right: 10px;
  top: 0px;
}
