#notification {
  .notification-title {
    font-weight: 700;
  }
  .notification--box {
    min-height: 120px;
    background: #fafafc;
    border-radius: 12px;
    text-align: start;
    box-shadow: 1px 2px 2px #88888845;

    .notification--textarea {
      font-size: 18px;
      color: #28293d;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .notification--box {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    .notification-title {
    }
    .notification--box {
      .notification--textarea {
        font-size: 14px;
      }
    }
  }
}
