#ContactUs {
  .contactUs--box {
    .contactUs--phone {
      text-align: end;
      img {
        width: 50px;
        height: auto;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
