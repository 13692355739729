.App {
  font-family: "Cairo", sans-serif;
}

.swal2-popup {
  border-radius: 16px !important;

  button {
    border-radius: 12px !important;
  }
}

a,
li {
  text-decoration: none !important;
  list-style: none !important;
}

ul {
  padding: 0;
  margin: 0;
}

.page-container {
  padding-top: 30px !important;

  section {
    margin-bottom: 50px !important;
  }

  min-height: calc(100vh - 300px);
}

body {
  min-width: 320px;

  @media (max-width: 320px) {
    overflow-x: scroll !important;
  }
}

#root {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .page-container {
    padding-top: 15px !important;

    section {
      margin-bottom: 25px !important;
    }
  }
}

[dir="rtl"] .slick-slide {
  float: left !important;
}

.slick-prev {
  left: -16px !important;
  right: auto !important;
}

.slick-next {
  right: -16px !important;
  left: auto !important;
}

.slick-arrow {
  transform: rotate(180deg);
}

.flipY {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.flipX {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

body {
  min-height: 100vh !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
.contain {
  object-fit: contain;
}

.card-container {
  padding-left: 12px;
  padding-right: 12px;
}

h1,
.h1 {
  font-size: 18px !important;
}
h2,
.h2 {
  font-size: 17px !important;
}
h3,
.h3 {
  font-size: 16px !important;
}
h4,
.h4 {
  font-size: 16px !important;
}
h5,
.h5 {
  font-size: 14px !important;
}
h6,
.h6 {
  font-size: 14px !important;
}
.h7 {
  font-size: 12px !important;
}
.h8 {
  font-size: 12px !important;
}
.h9 {
  font-size: 10px !important;
}

@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 22px !important;
  }
  h2,
  .h2 {
    font-size: 20px !important;
  }
  h3,
  .h3 {
    font-size: 18px !important;
  }
  h4,
  .h4 {
    font-size: 16px !important;
  }
  h5,
  .h5 {
    font-size: 14px !important;
  }
  h6,
  .h6 {
    font-size: 14px !important;
  }
  .h7 {
    font-size: 12px !important;
  }
  .h8 {
    font-size: 12px !important;
  }
  .h9 {
    font-size: 10px !important;
  }
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 28px !important;
  }
  h2,
  .h2 {
    font-size: 24px !important;
  }
  h3,
  .h3 {
    font-size: 18px !important;
  }
  h4,
  .h4 {
    font-size: 18px !important;
  }
  h5,
  .h5 {
    font-size: 16px !important;
  }
  h6,
  .h6 {
    font-size: 16px !important;
  }
  .h7 {
    font-size: 14px !important;
  }
  .h8 {
    font-size: 12px !important;
  }
  .h9 {
    font-size: 10px !important;
  }
}

@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 32px !important;
  }
  h2,
  .h2 {
    font-size: 28px !important;
  }
  h3,
  .h3 {
    font-size: 24px !important;
  }
  h4,
  .h4 {
    font-size: 20px !important;
  }
  h5,
  .h5 {
    font-size: 18px !important;
  }
  h6,
  .h6 {
    font-size: 16px !important;
  }
  .h7 {
    font-size: 14px !important;
  }
  .h8 {
    font-size: 12px !important;
  }
  .h9 {
    font-size: 12px !important;
  }
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 40px !important;
  }
  h2,
  .h2 {
    font-size: 32px !important;
  }
  h3,
  .h3 {
    font-size: 28px !important;
  }
  h4,
  .h4 {
    font-size: 24px !important;
  }
  h5,
  .h5 {
    font-size: 20px !important;
  }
  h6,
  .h6 {
    font-size: 18px !important;
  }
  .h7 {
    font-size: 16px !important;
  }
  .h8 {
    font-size: 14px !important;
  }
  .h9 {
    font-size: 12px !important;
  }
}

.btn {
  border-radius: 8px;
  padding: 6px 12px;
  box-shadow: unset !important;
  outline: none !important;
}

.btn-primary {
  background-color: #0063f7;

  &:hover {
    background-color: $nav--links-color-hover !important;
  }
}

a,
button,
textarea,
input,
.input-container,
select {
  &:hover,
  &:focus,
  &:active,
  &:visited {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: none !important;
  }
}

textarea,
input,
.input-container,
select {
  border-radius: 8px !important;
  border: 1px solid #e4e4eb !important;
  outline: 0 !important;
}
textarea,
input,
.input-container {
  padding: 6px 12px !important;
}

.rounded {
  &-1 {
    border-radius: 4px !important;

    &-top {
      border-radius: 4px 4px 0 0 !important;
    }

    &-bottom {
      border-radius: 0 0 4px 4px 0 important;
    }
  }
  &-2 {
    border-radius: 8px !important;

    &-top {
      border-radius: 8px 8px 0 0 !important;
    }

    &-bottom {
      border-radius: 0 0 8px 8px 0 important;
    }
  }
  &-3 {
    border-radius: 12px !important;

    &-top {
      border-radius: 12px 12px 0 0 !important;
    }

    &-bottom {
      border-radius: 0 0 12px 12px !important;
    }
  }
  &-4 {
    border-radius: 16px !important;

    &-top {
      border-radius: 16px 16px 0 0 !important;
    }

    &-bottom {
      border-radius: 0 0 16px 16px !important;
    }
  }
  &-5 {
    border-radius: 19px !important;

    &-top {
      border-radius: 19px 19px 0 0 !important;
    }

    &-bottom {
      border-radius: 0 0 19px 19px !important;
    }
  }
}

.showOpacity {
  opacity: 0.5;
}
.hideOpacity {
  opacity: 1;
}

.login--showConfirmPassword {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  left: 10px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  html[dir="ltr"] {
    left: auto !important;
    right: 10px !important;
  }
}
html[dir="ltr"] {
  .login--showConfirmPassword {
    left: auto !important;
    right: 10px !important;
  }
}
.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}

.cut-text {
  &-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &-2 {
    overflow-y: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-3 {
    overflow-y: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &-4 {
    overflow-y: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

* {
  text-transform: capitalize;
}

.capitalize {
  text-transform: capitalize !important;
}

.fill-light6,
.fill-light6 * {
  fill: theme-color(light6) !important;
}

.checkout-type-card {
  height: 100px !important;

  @media (max-width: 991px) {
    height: 75px !important;

  }
}

input, textarea, select {
  text-transform: none !important;
}