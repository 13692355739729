html[dir="rtl"] {
  .loyaltyPoints--section-btn {
    border-radius: 12px 0px 0px 12px !important;
  }
  .loyaltyPoints--section-inputField {
    border-radius: 0px 12px 12px 0px !important;
  }
}
html[dir="ltr"] {
  .loyaltyPoints--section-btn {
    border-radius: 0px 12px 12px 0px !important;
  }
  .loyaltyPoints--section-inputField {
    border-radius: 12px 0px 0px 12px !important;
  }
}
