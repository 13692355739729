#searchResults {
  .searchResults-title {
    font-size: 28px;
    font-weight: 700;
    text-align: start;
  }
  .searchResults--gallery {
    margin-block-start: -10px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .searchResults-title {
      font-size: 24px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .searchResults-title {
      font-size: 24px;
    }
    .searchResults--gallery {
    }
  }
  @media (max-width: 767px) {
    .searchResults-title {
      font-size: 16px;
    }
    .searchResults--gallery {
    }
  }
}
