.items--slider-image-hover, .slider-skeleton{
    height: calc(160px - 36px) !important;
    max-height: calc(160px - 36px) !important;
    min-height: calc(160px - 36px) !important;
    width: calc(160px - 36px) !important;
    max-width: calc(160px - 36px) !important;
    min-width: calc(160px - 36px) !important;
    border-radius: 16px;
    object-fit: cover;
  
    &:hover {
      opacity: 0.7;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
  

    object-fit: cover !important;
  
    @media (min-width: 320px) {
      height: calc(50vw - 36px) !important;
      max-height: calc(50vw - 36px) !important;
      min-height: calc(50vw - 36px) !important;
      width: calc(50vw - 36px) !important;
      max-width: calc(50vw - 36px) !important;
      min-width: calc(50vw - 36px) !important;
    }
  
    @include media-breakpoint-up(sm) {
      max-height: 234px !important;
      min-height: 234px !important;
      max-width: 234px !important;
      min-width: 234px !important;
    }
    @include media-breakpoint-up(md) {
      max-height: 208px !important;
      min-height: 208px !important;
      max-width: 208px !important;
      min-width: 208px !important;
    }
    @include media-breakpoint-up(lg) {
      max-height: 210px !important;
      min-height: 210px !important;
      max-width: 210px !important;
      min-width: 210px !important;
    }
    @include media-breakpoint-up(xl) {
      max-height: 255px !important;
      min-height: 255px !important;
      max-width: 255px !important;
      min-width: 255px !important;
    }
    @include media-breakpoint-up(xxl) {
      max-height: 236px !important;
      min-height: 236px !important;
      max-width: 236px !important;
      min-width: 236px !important;
    }


}

.slider-skeleton{
  * {
    height: 100% !important;
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    display: block !important;
  }
}