#branchesUsers {
  .branchesUsers--box {
    box-sizing: border-box;

    .content {
      height: 525px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        transition: all 0.3s ease-in-out;
        border-radius: 7px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #aaa;
          border-radius: 7px;
        }
      }
    }
  }


}

@media (min-width: 768px) {
  html[dir="rtl"] .branchesUsers--box-right {
    border-radius: 0px 16px 16px 0px !important;
  }
  html[dir="rtl"] .branchesUsers--box-left {
    border-radius: 16px 0px 0px 16px !important;
  }
  html[dir="ltr"] .branchesUsers--box-right {
    border-radius: 16px 0px 0px 16px !important;
  }
  html[dir="ltr"] .branchesUsers--box-left {
    border-radius: 0px 16px 16px 0px !important;
  }
}