#Login {
  .login--container {
    box-shadow: 5px 5px 5px #ddddddd1;

    a {
      color: #fff;
    }

    .login--showPassword {
      position: relative;
      width: 100%;
    }

  }
}
