#Category-page,
#sub-category-page {
  .category_heroImage_container {
    overflow: hidden;
    .parallelogram {
      width: 100%;
      height: 60px;
      margin: 0 0 0 -30px;
      -webkit-transform: skew(30deg);
      -moz-transform: skew(30deg);
      -o-transform: skew(30deg);
      overflow: hidden;
      position: relative;

      .category_heroImage_title {
        position: absolute;
        display: flex;
        align-items: center;
        top: -30px;
        left: -30px;
        right: -30px;
        bottom: -30px;
        padding-left: 60px;
        padding-right: 60px;
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);
      }
    }
  }
}
html[dir="rtl"] {
  #Category-page,
  #sub-category-page {
    .category_heroImage_container {
      overflow: hidden;
      .parallelogram {
        margin: 0 -30px 0 0;
        -webkit-transform: skew(-30deg);
        -moz-transform: skew(-30deg);
        -o-transform: skew(-30deg);

        .category_heroImage_title {
          -webkit-transform: skew(30deg);
          -moz-transform: skew(30deg);
          -o-transform: skew(30deg);
        }
      }
    }
  }
}
