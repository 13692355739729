#orderDetails {
  overflow-x: hidden;

  .orderDetails--box {
    box-shadow: 1px 2px 2px #88888845;
    .orderDetails--desc {
      font-size: 18px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
