
.toggle--row-padd {
  div {
    min-height: 100%;
  }
  .toggle--box {
    opacity: 0.5;
    border: 2px solid #c7c9d9;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &.active {
      opacity: 1;
      border: 2px solid #0d6efd;
      color: #0063f7 !important;
    }
  }

  .toggle--box-desc-main {
    font-weight: bold;
    color: #0063f7 !important;
  }

  .toggle--box-right,
  .toggle--box-right .img {
    border-radius: 16px 0 0 16px;
  }

  .toggle--box-left {
    border-radius: 0px 16px 16px 0px;
  }

  &:lang(ar) {
    .toggle--box-right,
    .toggle--box-right .img {
      border-radius: 0px 16px 16px 0 !important;
    }

    .toggle--box-left {
      border-radius: 16px 0 0 16px !important;
    }
  }
}
