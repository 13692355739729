@import "node_modules/react-modal-video/scss/modal-video.scss";

.video-image {
  .modal-video-body {
    padding: 0 !important;

    .modal-video-close-btn {
      display: none !important;
    }

    .modal-video-inner {
      min-width: 70vw !important;
      max-width: calc(100%) !important;
      height: auto !important;
    }
  }
  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.17) !important;
  }
}
