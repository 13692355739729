
#MyOrders {
  .myOrders--heroImage-box {
    background: #0063f7;
    border-radius: 16px;

    &-title {
      opacity: 1;
      margin-top: calc(-100% + 25px);

      &-hidden {
        color: #004fc4;
      }
    }
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
