$nav--login-color: #0063f7;
$nav--searchBox-placeholder-color: #e4e4eb;

.nav--container {
  a.nav--links {
    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $nav--links-color-hover !important;
    }
  }

  .badge {
    font-size: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
    border: 2px solid #e4e4eb;

    &.notifications {
      top: -5px;
      right: -5px;
      width: 30px;
      height: 30px;

      @media (min-width: 576px) {
        top: -10px;
        right: -10px;
        width: 24px;
        height: 24px;
      }
    }

    @media (min-width: 1200px) {
      width: 27px;
      height: 27px;
      top: -10px;
      right: -10px;
      font-size: 14px;
    }
  }

  .nav--col-icons-sec {
    img {
      width: 30px;
    }
  }

  .user-dropdown {
    transition-delay: 0.5s;

    .down_vector {
      transition: all 0.3s ease-in-out;
    }

    &.show {
      box-shadow: 0px 11px 25px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border-radius: 18px 18px 0 0;
      transition-delay: 0s;

      .nav--signed-dropdown {
        max-height: 1000px;
        animation-name: example;
        animation-duration: 0.5s;
      }

      @keyframes example {
        from {
          max-height: 0;
        }
        to {
          max-height: 1000px;
        }
      }

      .down_vector {
        transform: rotate(180deg);
      }
    }

    .dropdown-toggle::after {
      content: none;
    }

    .nav--signed-dropdown {
      inset: unset !important;
      transform: none !important;
      width: 100%;
      left: 0;
      right: 0;
      border-top: unset;
      border-radius: 0 0 18px 18px;
      border: none;
      box-shadow: 0px 27px 25px rgba(0, 0, 0, 0.1);
      min-width: unset;
      display: block;
      max-height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.5s ease-in-out;

      .dropdown-item {
        color: theme-color(light5);
        padding: 0.25rem 0.2rem;

        svg {
          fill: theme-color(light5);

          * {
            fill: theme-color(light5);
          }
        }

        p {
          overflow: hidden;
        }
        background-color: transparent;
        box-shadow: unset;
        outline: none;
      }

      a.dropdown-item, button.dropdown-item {
        &:hover,
        &:active,
        &:focus,
        &.active {
          background-color: transparent;

          box-shadow: unset;
          outline: none;
          color: $nav--links-color-hover;

          svg {
            fill: $nav--links-color-hover;

            * {
              fill: $nav--links-color-hover;
            }
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .navbar-collapse {
      height: 0;
      display: block !important;
      overflow: visible;

      .nav--collapse-props {
        z-index: 120;
        text-align: center;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 11px 25px rgba(0, 0, 0, 0.1);
        border-radius: 18px;
        top: 15px;
        width: 280px;
        max-height: 0px;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
      }

      &.show {
        .nav--collapse-props {
          max-height: 600px;
        }
      }

      &.collapsing {
        overflow: visible;
      }
    }
  }
}
