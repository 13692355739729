 

#editUser {
  .addNewUser--container-padd {
  }
  .addNewUser--title {
    font-weight: 600;
    font-size: 24px;

    display: flex;
    align-items: center;
    color: #000000;
  }
  .addNewUser--label {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: start;
    justify-content: start;
    align-items: start;
    color: #555770;
  }
  .addNewUser--input {
    background: #ffffff;
    border: 1px solid #e4e4eb;
    box-sizing: border-box;
    border-radius: 8px;

    box-shadow: none;

    &::placeholder {
      font-size: 14px;
      text-align: start;
      color: #999999;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
  .addNewUser--btn {
    width: 195px;
    height: 45px;
    background: #0063f7;
    border-radius: 8px;
    box-shadow: none;
    text-align: center;

    &:hover {
      background: $nav--links-color-hover;
    }
  }
  .addNewUser--label {
    .form-select {
      font-size: 14px;
      text-align: start;
      color: #555770;
      box-shadow: none;
      cursor: pointer;
      background-position: left 0.75rem center;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .addNewUser--title {
      font-size: 18px;
    }
    .addNewUser--label {
      font-size: 14px;
      color: #555770;
    }
    .addNewUser--input {
      &::placeholder {
        font-size: 12px;
      }
    }
    .addNewUser--btn {
      width: 120px;
      height: 35px;
    }
  }
  @media (max-width: 767px) {
    .addNewUser--mobile {
      width: 100%;
    }
    .addNewUser--title {
      font-size: 18px;
      justify-content: center;
    }
    .addNewUser--label {
      font-size: 14px;
    }
    .addNewUser--input {
      &::placeholder {
        font-size: 12px;
      }
    }
    .addNewUser--btn {
      width: 40%;
      height: 35px;
    }
  }
}
html[dir="ltr"] .form-select {
  background-position: right 0.75rem center !important;
}
