$slider_arrow_right: "../../../public/icons/Vector-right.svg";
$slider_arrow_left: "../../../public/icons/Vector-left.svg";

#MainSlider {
  .carousel-caption {
    position: unset;
    padding-top: unset;
    padding-bottom: 40px !important;
  }
  .carousel-indicators [data-bs-target] {
    border-radius: 50%;
    list-style: circle;
    height: 12px;
    width: 12px;
    border: 1px solid grey;
    background-color: transparent;
    border-collapse: separate;
  }
  .carousel-indicators {
    justify-content: start;
    margin: 0 !important;
    bottom: -14px !important;

    .active {
      background: orange !important;
    }
  }

  .carousel-control-prev-icon {
    width: 30px;
    background-image: url($slider_arrow_left);
    background-size: auto 100%;
    background-position: center center;
    filter: unset;
  }

  .carousel-control-next-icon {
    width: 30px;
    background-image: url($slider_arrow_right);
    background-size: auto 100%;
    background-position: center center;
    filter: unset;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 30px;
    opacity: unset;
  }

  .carousel-control-prev {
    left: -30px;
  }

  .carousel-control-next {
    right: -30px;
  }

  @media (max-width: 767px) {
    * {
      text-align: center;
    }

    .carousel-control-next,
    .carousel-control-prev {
      display: none !important;
    }

    .carousel-indicators {
      justify-content: center !important;
    }
  }
}

#latestSection {
  .items--slider-links {
    outline: none;
  }
}