.gallery-row {
  .categories--image-padd {
    border-radius: 16px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
.categories--image-padd,
.gallery-skeleton-col {
  height: calc(160px - 24px) !important;
  max-height: calc(160px - 24px) !important;
  min-height: calc(160px - 24px) !important;
  width: calc(160px - 24px) !important;
  max-width: calc(160px - 24px) !important;
  min-width: calc(160px - 24px) !important;

  @media (min-width: 320px) {
    height: calc(50vw - 24px) !important;
    max-height: calc(50vw - 24px) !important;
    min-height: calc(50vw - 24px) !important;
    width: calc(50vw - 24px) !important;
    max-width: calc(50vw - 24px) !important;
    min-width: calc(50vw - 24px) !important;
  }

  @include media-breakpoint-up(sm) {
    max-height: 246px !important;
    min-height: 246px !important;
    max-width: 246px !important;
    min-width: 246px !important;
  }
  @include media-breakpoint-up(md) {
    max-height: 216px !important;
    min-height: 216px !important;
    max-width: 216px !important;
    min-width: 216px !important;
  }
  @include media-breakpoint-up(lg) {
    max-height: 216px !important;
    min-height: 216px !important;
    max-width: 216px !important;
    min-width: 216px !important;
  }
  @include media-breakpoint-up(xl) {
    max-height: 261px !important;
    min-height: 261px !important;
    max-width: 261px !important;
    min-width: 261px !important;
  }
  @include media-breakpoint-up(xxl) {
    max-height: 196px !important;
    min-height: 196px !important;
    max-width: 196px !important;
    min-width: 196px !important;
  }
}
.gallery-skeleton-col {
  * {
    height: 100% !important;
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    display: block !important;
  }
}

////////////////////////////////////////////

@media (min-width: 577px) and (max-width: 767px) {
  .slick-dots li button:before {
    font-size: 10px;
    text-align: center;
    opacity: 0.4;
    color: orange;
  }
}
@media (max-width: 576px) {
  .slick-initialized {
    width: 100%;
  }
  .slick-slider img {
    margin-inline-start: 0%;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots li button:before {
    font-size: 10px;
    text-align: center;
    opacity: 0.4;
    color: orange;
  }
}
