.WishlistCards--box {
  min-height: 120px;


  .WishlistCards--price-title {
    font-weight: bold;
    font-size: 12px;
    text-align: start;
    color: #8f90a6;
  }
  .WishlistCards--div-icons {
    text-align: end;
    img{
      width: 22px;
      height: 22px;
    }
  }

}
